//
// your custom scss goes here
//

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
}

.border-left-ligth {
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card .card-badges {
    position:absolute;
    top:5px;
    left:5px;
  }