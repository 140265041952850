// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

// bootstrap & variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "utilities";
@import "user";
@import "~bootstrap/scss/bootstrap";

// components
@import "components/_index";



// card decorations
.card-bag {
    .card-header,
    .card-footer,
    .card-body {
      padding-right: rem(64);
    }
  
    &.equal {
      padding-right: 0;
  
      .card-wrap {
        padding-right: rem(56);
      }
    }
  
    &::after {
      transition: all 0.2s ease-in-out;
      position: absolute;
      right: rem(40);
      bottom: rem(40);
      display: inline-block;
      vertical-align: middle;
      font-family: bootstrap-icons;
      font-size: rem(24);
      line-height: 1em;
      z-index: 4;
      color: inherit;
      content: "\f179";
    }
  
    &:not(.card-hover-arrow):hover {
      &::after {
        right: rem(32);
        bottom: rem(32);
      }
    }
  }